import { createRouter, createWebHistory } from "vue-router";


const router = createRouter({
  history: createWebHistory(),
  routes:[
  { path: '/', name: 'home', component: () => import('./views/HomeView.vue') },
  { path: '/home', component: () => import('./views/HomeView.vue') },
  { path: '/about', name: 'about', component: () => import('./views/AboutView.vue') },
  { path: '/contact', name: 'contact', component: () => import('./views/ContactView.vue') },
  { path: '/:notFound(.*)', name: 'notFound', component: () => import('./views/NotFound.vue') }
  ],
  
});
// Inside your router configuration file

router.beforeEach((to, from, next) => {
  // Emit an event that the route is about to change
  window.dispatchEvent(new CustomEvent('route-changing'));
  next();
});


export default router
