<template>
  <div class="main_contaner">
    <the-header></the-header>
    <div class="router-view_container">
      <router-view />
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from "./components/layouts/TheHeader.vue";
import TheFooter from "./components/layouts/TheFooter.vue";

export default {
  components: {
    TheHeader,
    TheFooter,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --base-color: #fbf9f1;
  --yellow: #fecc00;
  --red:#D24545;
  /* --black: #3d3b40; */
  --black: #000;
}

.noSelect,
a {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus,
a:focus {
  outline: none !important;
}

html,
body {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  background: var(--base-color);
  padding: 0px;
  margin: 0px;
}

.main_contaner {
  display: flex;
  flex-direction: column;
}
.router-view_container {
  min-height: 90vh;
  padding: 10px 1vw;
  background-image: url("./assets/logo.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 80vw;
}
/* .router-view_container:after {
  content: " ";
  z-index:0;
  top:10vh;
  right: 0px;
  bottom: 0px;
  left: 0px;
  position: fixed;
  background-image: url("./assets/logo.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 80vw;
  opacity: 0.1;
} */
.no-scroll {
  overflow: hidden;
  height: 100%;
}
</style>
