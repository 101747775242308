<template>
  <div class="header">
    <div class="menu_container">
      <span @click="toggleMenu">{{ menuState }}</span>
      <span><router-link to="/">NADIR</router-link></span>
    </div>
    <div class="nav_container" :class="{ visible: isOpen }">
      <nav>
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/about">About</router-link></li>
          <li><router-link to="/contact">Contact</router-link></li>
          <li><router-link to="/Portfolio">Portfolio</router-link></li>
          <li><router-link to="/blog">Blog</router-link></li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false, // Starting state of menu: closed
    };
  },
  computed: {
    menuState() {
      return this.isOpen ? "CLOSE" : "MENU"; // returns CLOSE if the menu is open
    },
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen; // Changes the state of the menu
      if (this.isOpen) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }
    },
    closeMenu() {
      this.isOpen = false;
      document.body.classList.remove("no-scroll");
    }
  },
  mounted() {
    window.addEventListener('route-changing', this.closeMenu);
  },
  beforeUnmount() {
    window.removeEventListener('route-changing', this.closeMenu);
  },
};
</script>



<style scoped>
.header {
  color:var(--black);
  font-family: "Bebas Neue", sans-serif;
}
.menu_container {
  font-size: 4em;
  display: flex;
  justify-content: space-between;
  padding: 3px 1vw;
  max-width: 100%;
  height: fit-content;
  border-bottom: 5px solid var(--black);
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.menu_container a,
.nav_container nav li a {
  color: var(--black);
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

.nav_container li {
  /* font-size: calc(1px + (35 - 15) * ((100vw - 0px) / (980 - 500))); */
  font-size: 4em;
  list-style: none;
}

.nav_container ul {
  padding: 0 1vw;
}

.nav_container {
  background: var(--base-color);
  overflow: scroll;
  width: 100%;
  left:-200%;
  height: 100%;
  box-sizing: border-box;
  position: fixed;
  transition: 0.4s;
  z-index:10;
}

.visible {
  left: 0%;
}
</style>
