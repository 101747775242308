<template>
  <div class="footer">
    Nadir &copy; {{ currentYear }}
  </div>
</template>

<script>
export default {
  computed: {
    currentYear() {
      return new Date().getFullYear();
    }
  }
}
</script>

<style scoped>
.footer {
  font-family: "Bebas Neue", sans-serif;
  font-size:1.5em;
  padding: 3px 1vw;
}

</style>

